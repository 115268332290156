// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-help-center-mdx": () => import("./../src/pages/help-center.mdx" /* webpackChunkName: "component---src-pages-help-center-mdx" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-terms-and-conditions-mdx": () => import("./../src/pages/terms-and-conditions.mdx" /* webpackChunkName: "component---src-pages-terms-and-conditions-mdx" */),
  "component---src-pages-vax-mdx": () => import("./../src/pages/vax.mdx" /* webpackChunkName: "component---src-pages-vax-mdx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */)
}

